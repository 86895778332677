import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import {
  Anchor,
  BackTop,
  Button,
  Carousel,
  Drawer,
  Icon,
  Row,
} from "ant-design-vue";
import "@/assets/fonts/douYu.css";
import axios from "./utils/request";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

// 全局设置图片路径
Vue.prototype.imageUrl = "https://qn.guxuan.tech/";

Vue.use(Carousel);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Row);
Vue.use(BackTop);
Vue.use(Anchor);
Vue.use(Drawer);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
