import axios from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_NOTICE, // api 的 base_url
  method: "get", // default
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;",
  },
  timeout: 15000, // 请求超时时间
});
// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 导出axios实例
export default http;
